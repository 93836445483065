:root {
  --primary: #01497C;
}


.primary-color {
  color: #01497C;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
}

.primary-blue-font {
  color: #01497c !important;
}

.App-body {
  background-color: #01497c;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.captureButton {
  position: absolute;
  left: 45%;
  bottom: 5%
}

.solcontbox {
  margin-left: 40px;
  margin-bottom: 20px;
  color: black;
  border-color: white;
  background: white;
  box-shadow: 1px 1px 5px 1px #888888a6;
  font-size: 18px;
  width: auto;
  max-width: 600px;
}

.solcont {
  max-width: 600px;
  min-width: 500px;
  margin: auto;
  margin-bottom: 10px;
  font-family: "RobotoRegular";
}

.solcontrow {
  width: 350px;
  margin: auto;
}

.solExpTitle {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 0px;
}

.SolutionStepbox {
  height: fit-content;
  width: fit-content;
  margin: auto;
  padding: 15px;
  color: #01497c !important;
  background-color: white !important;
  border-color: #01497c !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  border-radius: 10px;
  border-width: 2px;
  border: 1px solid #01497c;
}

.backArrowBox {
  border: 1px solid #01497c;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: fit-content;
  height: fit-content;
  align-items: center;
  align-content: center;
  background-color: #ffffff;
  border-right-width: 0px;
}

.backArrowBoxMobile {
  margin-top: 15px;

}

.solTitle {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 0px;
}

.bookmarkMobile {
  margin-top: 17px;
  position: absolute;
  right: 10px;
}

.solBookmark {
  border: 1px solid #01497c;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: fit-content;
  height: fit-content;
  align-items: center;
  align-content: center;
  background-color: #ffffff;
  border-left-width: 0px;

}

.solClip1 {
  margin-top: -40px;
  margin-left: 20px;
}

.solClip2 {
  margin-top: -48px;
  margin-left: 264px;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.store-btn {
  width: 180px;
}

.store-btn2 {
  width: 180px;
  margin-left: 48px;
}

.store-btn:hover {
  transform: scale(1.09);
  transition: transform 0.3s ease;
}

.store-btn2:hover {
  transform: scale(1.09);
  transition: transform 0.3s ease;
}

.landingAbout {
  background-color: #c5eaf9;
  height: fit-content;
  width: 100%;
}

.aboutImgDiv {
  padding-left: 64px;
}

.aboutImage {
  width: 50vmin;
  height: 50vmin;
}

#aboutContent {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
}

.about-intro {
  color: #01497c;
}

.about-text {
  padding-top: 32px;
  color: #10497c;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  text-align: justify;
  height: fit-content;
  width: 900px;
}

.Logo-text {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

.App-header {
  z-index: 1;
  position: fixed;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: auto;
  padding-left: auto;
  background-color: #01497c;
}

#navbar {
  background: #01497c;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  top: 0;
  transition: 0.5s;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  font-size: 18;
}

.nav-Btn {
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
  padding: 6px 16px;
}

.nav-Btn:hover {
  transform: scale(1.2);
}

::-webkit-scrollbar {
  display: block;
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #01497c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

::-webkit-scrollbar:horizontal {
  display: none
}

input:focus {
  outline: none !important;
}

.landing-body {
  background: #fff;
  color: #10497c;
  overflow: hidden;
  width: 100%;
  height: fit-content;
}

.calScroll {
  margin: 5px;
  width: 250px;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}

.landing-display {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  overflow: hidden;
  height: fit-content;
  width: 100%;
}

.landing-content {
  width: 65vw;
  margin: auto;
}

.landingImgDiv {
  width: 30vw;
  padding-right: 64px;
}

.landingImage {
  width: 50vmin;
  height: 50vmin;
}

.landing-para {
  font-size: 30px;
}

.landing-para span {
  font-weight: bold;
}

.landing-intro {
  padding-top: 32px;
  font-size: 84px;
}

.landing-intro span {
  font-weight: bold;
}

.info-intro {
  padding-top: 90px;
  font-size: 42px;
  color: #01497c;
}

.info-intro span {
  font-weight: bold;
}

.footer-info {
  display: flex;
  flex-direction: row;
  color: #01497c;
  justify-content: center;
  margin: auto;
  padding: 48px;
}

.footer-address-infos {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin: auto;
  color: #01497c;
  justify-content: center;
  width: 20vw;
  height: fit-content;
}

.footer-quick-links {
  width: 30vw;
  height: fit-content;
  font-size: 16px;
}

.footer-appstore-links {
  width: 30vw;
  height: fit-content;
  text-align: center;
  font-size: 16px;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

.footer-address-infos span {
  padding-top: 24px;
}

.footer-copyright-info {
  margin: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #01497c;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.ul-links-list {
  cursor: pointer;
  list-style-type: none;
}

.landing-des {
  padding-top: 32px;
  font-size: 24px;
}

.App-body {
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 350px;
  display: flex;
  flex-direction: column;
}

.signup-body {
  background-color: #fff;
}

.contentSignUp {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  overflow: hidden;
  height: fit-content;
  width: 100%;
}

.signupImg {
  width: 50vmin;
  height: 50vmin;
}

.Login-Btn {
  background: #01497c;
  width: 300px;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  height: 36px;
  padding: 7px;
  font-weight: 500;
  text-align: left;
  padding-left: 64px;
}

.Login-cta {
  font-size: 15px;
  margin-top: 30px;
}

.Login-cta .Signin-btn {
  cursor: pointer;
}

.Login-cta .Signup-btn {
  cursor: pointer;
}

.text-blue {
  color: #01497c;
}

.Login-cta .TermsAndCondition-btn {
  cursor: pointer;
  text-decoration: underline;
}

.Login-cta .pricacyPolicy-btn {
  cursor: pointer;
  text-decoration: underline;
}

.Login-cta .getHelp-btn {
  cursor: pointer;
  text-decoration: underline;
}

.Login-Icon {
  position: absolute;
  top: 6px;
  left: 30px;
}

.Google-Icn {
  width: 24px;
  height: 24px;
}

.App-link {
  color: #61dafb;
}

.image-icons {
  height: 70px;
  width: 70px;
  justify-content: center;
  text-align: left;
}

.cardsContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: left;
  margin: auto;
  width: 100%;
  padding: 0 60px;
  font-size: 12px;
}

.cards {
  color: #01497c;
  background-color: #c5eaf9;
  max-height: max-content;
  justify-content: center;
  text-align: center;
  width: 20%;
  padding: 30px;
  box-sizing: border-box;
}

.obfCards {
  max-height: max-content;
  justify-content: center;
  text-align: center;
  width: 20%;
  padding: 30px;
  box-sizing: border-box;
}

.obfCards:hover {
  transform: scale(1.09);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.imgCard {
  width: 100px;
  height: 180px;
}

.obf {
  width: 30vmin;
  height: 30vmin;
}

.mobileView {
  background-color: #c5eaf9;
}

.obfView {
  padding-top: 150px;
  padding-bottom: 150px;
  height: fit-content;
  width: 100%;
}

.cardTitle {
  padding-top: 32px;
  font-size: 18px;
  font-weight: 500;
}

.obfTitle {
  color: #fff;
  padding-top: 32px;
  font-size: 16px;
  font-weight: 500;
}

.Input-theme {
  border-radius: 8px !important;
  width: 343px !important;
  height: 48px !important;
  background: #ffffff !important;
  font-size: 15px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-Layout {
  color: #000000;
}

.App-Layout .vertical-menu-inline,
.App-Layout .vertical-body {
  min-height: 100vh;
  background: #e2ecff;
}

.App-Layout .vertical-menu-inline,
.App-Layout .vertical-menu-slide {
  background: white;
}

.App-Layout .vertical-menu-slide {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  z-index: 100;
  width: 250px;
}

.vb-header {
  height: 50px;
}

.ham-nav-icn {
  height: 67px;
  padding-top: 24px;
  padding-left: 12px;
  text-align: center;
  margin-bottom: 20px;
}

.ham-men-close {
  position: absolute;
  top: 21px;
  right: 15px;
  cursor: pointer;
}

.verti-nav-cell {
  text-align: left;
  color: Black;
  font-family: "SF UI Display";
  font-weight: 500;
  font-size: 15px;
  padding: 15px;
  cursor: pointer;
}

.verti-nav-cell-link {
  width: 100%;
  text-decoration: none;
}

.verti-nav-cell-link:hover {
  text-decoration: none;
}

.verti-nav-cell-link.active .verti-nav-cell {
  background: #e2ecff;
  font-weight: bold;
}

.verti-nav-cell:hover {
  background: #e8e8e8;
}

.verti-nav-cell .verti-nav-icons {
  padding-right: 8px;
  position: relative;
  top: -2px;
  width: 29px;
  display: inline-block;
}

.hamburger-menu {
  position: relative;
  top: 16px;
  cursor: pointer;
}

.fm-main-logo {
  position: relative;
  top: 10px;
  left: 12px;
}

.h2-mn-heading {
  font-weight: 600;
  font-size: 30px;
}

.mn-sub-heading {
  font-weight: normal;
  font-size: 16px;
}

.forgot-password {
  font-size: 11pt;
  text-align: left;
  color: white;
  cursor: pointer;
}

.sb-heading-login {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #01497c;
}

.submit-btn-fm {
  color: white !important;
  background-color: #01497c !important;
  border-color: #01497c !important;
  padding: 5px 20px !important;
  margin: 10px;
  /* font-family: Inter !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  border-radius: 10px;
  font-family: "RobotoRegular";
}

.submit-btn-fm.bl-theme {
  color: white !important;
  background-color: #01497c !important;
}

.submit-btn-fm.bl-theme:hover {
  color: #01497c !important;
  background-color: white !important;
  border-color: #01497c !important;
}

.submit-btn-fm.small {
  padding: 2px 14px !important;
  font-size: 14px !important;
}

.submit-btn-fm:disabled {
  color: #01497c !important;
  background-color: white !important;
  border-color: white !important;
}

.submit-btn-fm:hover {
  color: white !important;
  background-color: #01497c !important;
  border-color: white !important;
}

.cancel-btn-fm {
  color: #01497c !important;
  background-color: white !important;
  border-color: white !important;
  padding: 6px 20px !important;

  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.cancel-btn-fm.bl-theme {
  color: white !important;
  background-color: #01497c !important;
}

.cancel-btn-fm.bl-theme:hover {
  color: #01497c !important;
  background-color: white !important;
  border-color: #01497c !important;
}

.cancel-btn-fm:disabled {
  color: #01497c !important;
  background-color: white !important;
  border-color: white !important;
}

.cancel-btn-fm:hover {
  color: white !important;
  background-color: #01497c !important;
  border-color: white !important;
}

.toggle-show-password {
  font-size: 14pt;
  top: 10px;
  right: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.sol-card-box .sol-type {
  font-size: 5pt;
}

.equation-box.sol-card-box .edit-equation {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  float: right;
  padding-right: 10px;
  color: #01497c;
  cursor: pointer;
}

.equation-box.sol-card-box .edit-equation .edit-eq-icn {
  margin-right: 4px;
  position: relative;
  top: -1px;
}

/* Calculator styles */
.calc-wrapper {
  max-width: 350px;
  height: 500px;
  background-color: white;
  color: #01497c;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 20px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "RobotoRegular";
}

.calc-display-area {
  width: 300px;
  height: 200px;
  margin: 0px auto;
  position: relative;
}

.calc-display-wrapper_placeholder {
  text-align: center;
  padding-top: 40px;
}

.calc-display-wrapper_display {
  padding-top: 20px;
}

.calc-bottom {
  position: absolute;
  bottom: 0;
}

.theme-button {
  color: white;
  background-color: #01497c;
  border-radius: 100px;
  border: 0px solid white;
  font-size: 10px;
  text-align: center;
}

.see-steps-button {
  width: 80px;
  height: 28px;
  left: 10px;
  bottom: 10px;
  font-size: 13px;
}

.calc-display-equation {
  margin-left: 10px;
  margin-top: 18px;
}

.calc-display-wrapper_error {
  color: #fc6252;
  text-align: center;
  font-size: 10px;
  width: 100%;
  bottom: 10;
}

.calc-display-wrapper_solution_card {
  width: 100%;
  height: 94px;
  border-radius: 10px;
  border: 1px solid #fbfbfb;
  background-color: #ecebeb62;
  bottom: 10px;
}

.calc-display-wrapper_display_clear_button {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 1px;
  float: right;
  margin-top: -20px;
}

.calc {
  max-width: 350px;
  height: 278px;
  margin: 0px;
  margin-top: 10px;
  width: 100%;
}

.calc_tile__ctrl__btn {
  width: 25%;
  height: 20%;
  background-color: #ffffff00;
  border: 0px solid white;
  padding: 0px 0px;
  font-size: 25px;
  color: #01497C;
  font-weight: 600;
}

/* calc_tile__ctrl__btn:active{
  background-color: #e2ecff;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
} */

.calc_btn_inside {
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
}

.calc_btn_inside_disable {
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  color: #333333;
}

.calc_btn_inside:active {
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  background-color: #e2ecff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);
}

.calc_tile__ctrl__img {
  width: 25%;
  height: 20%;
  object-fit: fill;
}

.calc_tile__ctrl__btn_dot {
  font-size: 25px;
  font-weight: 500;
  color: #01497C;
}

.calc_tile__ctrl--empty {
  color: #ffffff00;
}

.calc_tile__ctrl--important {
  background-color: #ecebeb62;
}

.calc_tile__ctrl--important-special {
  background-color: #01497C;
  color: white;
  border-radius: 10px;
}

.zci--calculator {
  bottom: 0;
}

/* Typography */

.zci--calculator,
.zci--calculator .tile__ctrl__btn,
.zci--calculator .tile__ctrl__toggle,
.zci--calculator .tile__skip-calc {
  font-family: "DDG_ProximaNova", "DDG_ProximaNova_UI_0", "DDG_ProximaNova_UI_1",
    "DDG_ProximaNova_UI_2", "DDG_ProximaNova_UI_3", "DDG_ProximaNova_UI_4",
    "DDG_ProximaNova_UI_5", "DDG_ProximaNova_UI_6", "Proxima Nova",
    "Helvetica Neue", "Helvetica", "Segoe UI", "Nimbus Sans L",
    "Liberation Sans", "Open Sans", FreeSans, Arial, sans-serif;
  background-color: #01497c;
  align-content: bottom;
}

/* Show UI when CSS is loaded */

.zci--calculator .tile--calculator {
  display: block !important;
  outline: none;
}

/* Layout */

.zci--calculator .zci__body {
  padding-left: 0px;
}

.zci--calculator .tile__calc {
  height: 352px;
}

.zci--calculator .tile__calc__col {
  height: 251px;
  width: 35%;
  max-width: 295px;
  /* half width of search box */
  float: left;
  display: inline-block;
  border-bottom: 1px solid #c8c8c8;
  box-sizing: border-box;
}

/* Tabs */

/* .zci--calculator .tile__calc .tile__tabs {
  box-sizing: border-box;
  -webkit-touch-callout: none;
} */

/* Allow user-select on history tab */
.zci--calculator .tile__tab__sci,
.zci--calculator .tile__tab__basic {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Controllers */

.zci--calculator .tile__calc .tile__ctrl__btn,
.zci--calculator .tile__calc .tile__ctrl__toggle {
  float: left;
  position: relative;
  box-sizing: border-box;
  height: 50px;
}

/* Toggle */

.zci--calculator .tile__calc .tile__ctrl__toggle {
  width: 50%;
  padding: 15px 0;
  margin: 0;
  background: #ebebeb;
  border: 1px solid #c8c8c8;
  border-bottom: 0;
  border-right: 0;
  color: #2e2e2e;
  font-size: 0.8em;
  text-align: center;
}

/* The switch - the box around the slider */

.zci--calculator .tile__ctrl__toggle-indicator {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 14px;
}

.zci--calculator .tile__ctrl__toggle-indicator input {
  display: none;
}

.zci--calculator .tile__ctrl__toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.zci--calculator .tile__ctrl__toggle-slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus+.tile__ctrl__toggle-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.tile__ctrl__toggle-slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

.zci--calculator .tile__ctrl__toggle-slider.tile__ctrl__round-icon {
  border-radius: 34px;
  margin: 3px 0;
  padding: 7px 0;
}

.zci--calculator .tile__ctrl__toggle-slider.tile__ctrl__round-icon:before {
  border-radius: 50%;
}

/* Buttons */

.zci--calculator .tile__calc .tile__ctrl__btn {
  width: 25%;
  padding: 0;
  margin: 0;
  background: white;
  border: 0px solid white;
  border-bottom: 0;
  border-right: 0;
  color: #2e2e2e;
  text-align: center;
  font-size: 1.1em;
}

.zci--calculator .tile__calc .tile__ctrl--double {
  width: 50%;
}

.zci--calculator .tile__calc .tile__ctrl--ops,
.zci--calculator .tile__calc .tile__tab__sci .tile__ctrl__btn {
  background: #ebebeb;
  font-weight: lighter;
}

.zci--calculator .tile__calc .tile__ctrl--important {
  background: #ecebeb;
  font-family: "DDG_ProximaNova_UI_0", "DDG_ProximaNova_UI_1",
    "DDG_ProximaNova_UI_2", "DDG_ProximaNova_UI_3", "DDG_ProximaNova_UI_4",
    "DDG_ProximaNova_UI_5", "DDG_ProximaNova_UI_6", "Proxima Nova",
    "Helvetica Neue", "Helvetica", "Segoe UI", "Nimbus Sans L",
    "Liberation Sans", "Open Sans", FreeSans, Arial, sans-serif;
  font-weight: lighter;
  font-size: 1.5em;
  /* padding-bottom: 0.1em; */
  /* border-right: 1px solid #c8c8c8; */
  line-height: 1px;
}

.zci--calculator .tile__calc .tile__ctrl--important--special {
  background: #01497c;
  color: white;
}

/* Ctrl (Button, Toggle) */

.zci--calculator .tile__calc .tile__ctrl__btn,
.zci--calculator .tile__calc .tile__ctrl__toggle {
  cursor: pointer;
}

.zci--calculator .tile__calc .tile__ctrl__btn:focus,
.zci--calculator .tile__calc .tile__ctrl__toggle:focus,
.zci--calculator .tile__calc .tile__ctrl__btn:active,
.zci--calculator .tile__calc .tile__ctrl__toggle:active {
  outline: none;
}

.no-touch .tile__calc .tile__ctrl__btn:hover,
.no-touch .tile__calc .tile__ctrl__toggle:hover {
  background-color: #dadada;
}

.zci--calculator .tile__calc .tile__ctrl__btn:active,
.zci--calculator .tile__calc .tile__ctrl__toggle:active {
  background-color: #cacaca;
}

.no-touch .tile__calc .tile__ctrl--important:hover {
  background-color: #ddc750;
}

.zci--calculator .tile__calc .tile__ctrl--important:active {
  background-color: #c8a900;
}

/* Accessibility skip handle */

.zci--calculator .tile__calc .tile__skip-calc {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: -9999;
  background: #e8e8e8;
  border: 0;
  padding: 0.3em 0.8em;
  font-size: 1em;
  margin: 1px;
}

.zci--calculator .tile__calc .tile__skip-calc:focus {
  pointer-events: all;
  opacity: 1;
  z-index: 1;
}

/* Input trap */

.zci--calculator .tile__calc .tile__input-trap {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 1px;
  width: 1px;
  z-index: -9999;
}

/* Display */

.zci--calculator .tile__calc .tile__display {
  background: #fff;
  border: 1px solid #c8c8c8;
  border-bottom: 0;
  padding: 0.8em;
  box-sizing: border-box;
  width: 70%;
  max-width: 590px;
  /* Match width of search box */
  /* border-right: 0; */
  height: 115px;
  position: relative;
  box-shadow: inset -1px -1px 0px #fff, inset 1px 1px 0px #fff;
  transition: box-shadow 0.3s;
  /* Text overflow */
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
}

.zci--calculator .tile__calc .tile__display:hover {
  box-shadow: inset -1px -1px 0px #999, inset 1px 1px 0px #999;
}

.zci--calculator .tile__calc .tile__display.selected {
  box-shadow: inset -1px -1px 0px #61a0c7, inset 1px 1px 0px #61a0c7;
}

.zci--calculator .tile__calc .tile__display:focus {
  outline: none;
}

.zci--calculator .tile__calc .tile__display__aside--wrap {
  position: relative;
  overflow: hidden;
  height: 1.3em;
}

.zci--calculator .tile__calc .tile__display__main,
.zci--calculator .tile__calc .tile__display__aside {
  display: block;
}

.zci--calculator .tile__calc .tile__display__aside {
  font-size: 1.1em;
  letter-spacing: 0.025em;
  color: #909090;
  font-weight: lighter;
  position: absolute;
  right: 0;
}

.zci--calculator .tile__calc .tile__display__main {
  color: #101010;
  font-weight: lighter;
  font-size: 2.5em;
  letter-spacing: -0.05em;
  padding-right: 0.05em;
  cursor: default;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  outline: 0;
  float: right;
}

.zci--calculator .tile__calc .tile__display__main .pseudoBrace {
  color: #d3d3d3;
}

/* Formula presentation: Big */

.zci--calculator .tile__calc .tile__display__main sup {
  top: -0.8em;
  left: 0.1em;
  padding-right: 0.2em;
}

.zci--calculator .tile__calc .tile__display__main .formula__placeholder {
  color: #909090;
}

/* Formula presentation: Small */

.zci--calculator .tile__calc .tile__display__aside sup {
  top: -0.8em;
}

/* Calc display options */

.zci--calculator .tile__calc .tile__options {
  bottom: 0.4em;
  padding: 0 0.8em;
  width: 100%;
  right: 0;
  left: 0;
  position: absolute;
  display: table;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}

.zci--calculator .tile__calc .tile__option {
  transition: width 0.5s;
  font-size: 0.8em;
  width: auto;
  text-align: center;
  display: table-cell;
  color: #9e9e9e;
  width: 50%;
  font-weight: 200;
  letter-spacing: 0.03em;
}

.tile__calc .tile__option span {
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: -0.5em;
}

.zci--calculator .tile__calc .tile__option--active {
  color: #54a3bb;
  font-weight: 400;
}

.tile__calc .tile__option--active span {
  cursor: default;
}

.zci--calculator .tile__calc .tile__option__sci {
  text-align: left;
}

.zci--calculator .tile__calc .tile__option__basic {
  text-align: right;
}

.zci--calculator .tile__calc .tile__option__history {
  display: none;
  text-align: right;
}

.zci--calculator .tile__calc .tile__option:hover {
  text-decoration: none;
}

.calculator {
  flex-direction: column;
  /* flex: 1; */
}


.labelCal {
  display: inline-block;
  position: relative;
  min-width: 1em;
  padding: 5px;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;

}

.tpl {
  white-space: pre;
  /* max-width : could be wised to set a maximum width and overflow:hidden; */
}

.test {
  font-family: inherit;
  font-size: inherit;
  position: absolute;
  vertical-align: top;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
}

/* History tab */

.zci--calculator .tile__calc .tile__history {
  width: 30%;
  background-color: #f9f9f9;
  position: relative;
  margin-top: -100px;
  padding: 0;
  border: 1px solid #ccc;
  height: 351px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s;
}

.zci--calculator .tile__calc .tile__history--hidden {
  width: 0px;
}

/* History item */

.zci--calculator .tile__calc .tile__past-calc {
  box-sizing: border-box;
  padding: 0.4em 0.6em 0.4em;
  height: 50px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  text-align: right;
  font-size: 1.1em;
  overflow: hidden;
  transition: 0.5s all;
}

.zci--calculator .tile__calc .tile__past-calc.tile__past-calc--hidden {
  height: 0px;
  padding-top: 0;
  padding-bottom: 0;
}

/******************/
/* TEXT SIZE ADJS */
/******************/

/* Largest */

.set-text--largest .tile__calc .tile__display__main {
  font-size: 2em;
  font-weight: 400;
}

.set-text--largest .tile__calc .tile__past-calc {
  padding: 0.2em 0.4em;
}

.set-text--largest .tile__calc .zci--calculator .zci__main--detail {
  max-width: 61.9em;
}

.set-text--largest .tile__calc .tile__past-result {
  margin-top: -0.2em;
}

.zci--calculator .tile__calc .tile__past-calc:hover {
  background: #eee;
}

.zci--calculator .tile__calc .tile__past-formula {
  display: block;
  color: #aaa;
  font-weight: 100;
  font-size: 0.8em;
}

.zci--calculator .tile__calc .tile__past-result {
  font-weight: 700;
  color: #191919;
  font-size: 1.2em;
  display: inline-block;
}

/* Big Widescreens >= 1200px (Default) */

.zci--calculator .zci__main {
  max-width: 60em;
}

/*****************/
/* MEDIA QUERIES */
/*****************/

/* Monitors with MQs >= 960 */

@media only screen and (min-width: 60em) {



  .captureButton {
    position: absolute;
    left: 45%;
    bottom: 15%
  }

  .solcontbox {
    max-width: 600px;
    min-width: 450px;
  }

  .solcont {
    max-width: 400px;
    min-width: 500px;
    margin: auto;
    margin-bottom: 10px;
    font-family: "RobotoRegular";
  }


  .SolutionStepbox {
    margin: auto;
    padding: 10px;
  }

  .backArrowBox {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .backArrowBoxMobile {
    display: none;

  }

  .bookmarkMobile {
    display: none;
  }

  .solBookmark {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .zci--calculator .tile__calc .tile__options {
    bottom: -1em;
  }
}

/* Tablets <= 960 */

@media only screen and (max-width: 60em) {


  .captureButton {
    position: absolute;
    left: 45%;
    bottom: 5%
  }

  .solcontbox {
    max-width: 470px;
    min-width: 400px;
  }

  .solcont {
    max-width: 600px;
    min-width: 500px;
    margin: auto;
    margin-bottom: 10px;
    font-family: "RobotoRegular";
  }

  .SolutionStepbox {
    margin: auto;
    padding: 10px;
  }

  .backArrowBox {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .solBookmark {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .backArrowBoxMobile {
    display: none;

  }

  .bookmarkMobile {
    display: none;
  }

  /* show only one calc tab */
  .zci--calculator .tile__calc .tile__tabs {
    overflow: hidden;
  }

  .zci--calculator .tile__calc .tile__calc__col {
    width: 295px;
  }

  /* default: basic tab */
  .zci--calculator .tile__calc .tile__tabs .tile__tab__sci {
    margin-left: -295px;
  }

  .zci--calculator .tile__calc .tile__display {
    max-width: 295px;
  }

  .zci--calculator .zci__main--detail {
    max-width: 43.8em;
  }

  .zci--calculator .tile__calc .tile__history {
    max-width: 295px;
    transition: width 0.6s;
    position: absolute;
  }

  /* show 3 tab options */
  .zci--calculator .tile__calc .tile__display__main {
    margin-top: -0.2em;
  }

  .zci--calculator .tile__calc .tile__display .tile__options {
    margin-top: 0em;
    margin-bottom: 0px;
    display: block;
  }

  .zci--calculator .tile__calc .tile__display .tile__option {
    float: left;
  }

  .zci--calculator .tile__calc .tile__display .tile__option__sci {
    text-align: left;
  }

  .zci--calculator .tile__calc .tile__display .tile__option__basic {
    text-align: right;
  }

  .App {
    display: block;
    overflow: hidden;
  }

  .App-header {
    display: none;
    content: "";
  }

  .landingImgDiv {
    display: none;
  }

  .landing-content {
    width: 100%;
  }

  .contentSignUp {
    flex-direction: column;
  }

  .signupImg {
    width: 60vmin;
    height: 60vmin;
  }

  .landing-para {
    font-size: 24px;
    text-align: center;
    margin: auto;
    padding: 0 40px;
    height: 60px;
  }

  .about-text {
    justify-content: center;
    padding: 48px 36px;
    height: fit-content;
    width: 100%;
  }
}

/* Mobiles <= 740 */

@media only screen and (max-width: 43.8em) {


  .captureButton {
    position: absolute;
    left: 45%;
    bottom: 5%
  }

  .solcont {
    max-width: '100%';
    min-width: auto;
  }

  .solcontbox {
    max-width: 300px;
  }

  .solcontrow {
    width: 300px;
    margin: auto;
  }

  .SolutionStepbox {

    margin: 0;
    padding: 0px;
  }

  .backArrowBox {
    display: none;
  }

  .solBookmark {
    display: none;
  }


  .backArrowBoxMobile {
    display: flex;

  }

  .bookmarkMobile {
    display: flex;
  }

  .solTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -25px;
  }

  .solExpTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -30px;
  }

  .zci--calculator .tile__calc .tile__display {
    width: 50%;
  }

  .App-header {
    display: none;
    content: "";
  }

  .nav-Btn {
    opacity: 0;
  }

  .App {
    display: block;
    overflow: hidden;
  }

  .landing-para {
    font-size: 14px;
    text-align: center;
    margin: auto;
    padding: 0 40px;
    height: 40px;
  }

  .landing-intro {
    display: grid;
    justify-content: space-evenly;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
    font-size: 50px;
  }

  .info-intro {
    display: grid;
    justify-content: space-evenly;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
    font-size: 32px;
  }

  .footer-info {
    flex-direction: column;
    margin: auto;
    padding: 24px;
    height: fit-content;
  }

  .footer-address-infos {
    padding: 16px;
    width: 100%;
    font-size: 16px;
  }

  .footer-quick-links {
    margin: auto;
    padding-left: 16px;
    padding-top: 24px;
    width: 100%;
  }

  .footer-appstore-links {
    text-align: start;
    padding-left: 16px;
    padding-top: 24px;
    width: 100%;
  }

  .footer-copyright-info {
    text-align: center;
    font-size: 14px;
  }

  .landing-des {
    font-size: 14px;
  }

  .store-btn {
    width: 120px;
  }

  .store-btn2 {
    width: 120px;
  }

  .aboutImgDiv {
    padding-left: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }

  .aboutImage {
    width: 50vmin;
    height: 50vmin;
  }

  #aboutContent {
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
  }

  .about-intro {
    font-size: 18px;
    padding-bottom: 24px;
  }

  .about-text {
    justify-content: center;
    padding: 0 24px;
    font-size: 12px;
    height: fit-content;
    width: 100%;
  }

  #SignUpContent {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardsContent {
    flex-direction: column;
    margin: 10px 24px;
    padding: 40px;
    justify-content: center;
    width: 100%;
    height: max-content;
  }

  .cards {
    margin: auto;
    width: 100%;
    height: max-content;
    box-shadow: none;
    font-size: 12px;
  }

  .obfCards {
    margin: auto;
    width: 100%;
    height: max-content;
    box-shadow: none;
    font-size: 12px;
  }

  .mobileView {
    height: fit-content;
  }

  .obf {
    width: 60vmin;
    height: 60vmin;
  }

  .obfView {
    height: fit-content;
    overflow: hidden;
  }

  .obfCards:hover {
    transform: none;
  }

  .zci--calculator .tile__calc .tile__calc__col {
    width: 100%;
  }

  .zci--calculator .tile__calc .tile__history {
    max-width: 260px;
  }
}

/* Custom <= 660 */

@media only screen and (max-width: 37.4em) {

  .captureButton {
    position: absolute;
    left: 40%;
    bottom: 5%
  }

  .solcont {
    max-width: '100%';
    min-width: auto;
  }

  .solcontbox {
    min-width: 210px;
    max-width: 280px;
    margin-left: 10px;
  }

  .solcontrow {
    width: 300px;
    margin: auto;
  }

  .solClip1 {
    margin-left: 10px;
  }

  .solClip2 {
    margin-left: 235px;
  }

  .SolutionStepbox {
    margin: 0;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .backArrowBox {
    display: none;
  }

  .solBookmark {
    display: none;
  }



  .backArrowBoxMobile {
    display: flex;

  }

  .solTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -25px;
  }

  .solExpTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -30px;
  }

  .bookmarkMobile {
    display: flex;
  }

  .tile--calculator {
    width: 100%;
    box-sizing: border-box;
  }

  .zci--calculator .tile__calc {
    overflow: hidden;
    width: 100%;
  }

  .zci--calculator .tile__calc .tile__display {
    width: 100%;
    max-width: none;
    border-right: 1px solid #d8d8d8;
  }

  /* .zci--calculator .tile__calc .tile__tabs {
    width: 300%;
    position: relative;
    left: -100%;
    transition: left 0.7s;
  } */
  .zci--calculator .tile__calc .tile__tabs.tile__tabs--single-basic {
    left: -100%;
  }

  .zci--calculator .tile__calc .tile__tabs.tile__tabs--single-sci {
    left: 0;
  }

  .zci--calculator .tile__calc .tile__tabs.tile__tabs--single-history {
    left: -200%;
  }

  .zci--calculator .tile__calc .tile__calc__col {
    width: 33.33%;
    max-width: 33.33%;
    border-right: 1px solid #ccc;
  }

  .zci--calculator .tile__calc .tile__tabs .tile__tab__sci:first-child {
    margin-left: 0;
  }

  .zci--calculator .tile__calc .tile__history {
    position: relative;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    height: 251px;
  }

  /* 3 tab options */
  .zci--calculator .tile__calc .tile__display .tile__option {
    width: 33%;
  }

  .zci--calculator .tile__calc .tile__display .tile__option__basic {
    text-align: center;
  }

  .zci--calculator .tile__calc .tile__display .tile__option__history {
    display: table-cell;
  }

  .is-mobile .zci--calculator .zci__body {
    padding-left: 2px;
    padding-right: 0;
  }
}

/* Custom >= 660  & Tablets <= 960 */

@media only screen and (min-width: 37.4em) and (max-width: 60em) {
  .solcont {
    max-width: '100%';
    min-width: auto;
  }


  .captureButton {
    position: absolute;
    left: 45%;
    bottom: 18%
  }

  .SolutionStepbox {
    margin: auto;
    padding: 10px;
  }

  .backArrowBox {
    display: flex;
  }

  .backArrowBoxMobile {
    display: none;

  }

  .bookmarkMobile {
    display: none;
  }

  .solBookmark {
    display: flex;
  }

  .zci--calculator .tile__tabs.tile__tabs--single-sci .tile__tab__basic {
    width: 0;
    display: none;
  }

  .zci--calculator .tile__tabs.tile__tabs--single-sci .tile__tab__sci {
    margin-left: 0;
    border-right: 1px solid #c8c8c8;
  }

  .zci--calculator .tile__tabs.tile__tabs--single-basic .tile__tab__basic {}
}

@media only screen and (max-width: 21em) {
  .solcont {
    max-width: '100%';
    min-width: auto;
  }

  .captureButton {
    position: absolute;
    left: 45%;
    bottom: 5%
  }

  .solcontbox {
    max-width: 230px;
  }

  .solcontrow {
    width: 280px;
    margin: auto;
  }

  .SolutionStepbox {
    margin: 0;
    padding: 0px;
  }

  .backArrowBox {
    display: none;
  }

  .solBookmark {
    display: none;
  }

  .backArrowBoxMobile {
    display: flex;

  }

  .bookmarkMobile {
    display: flex;
  }

  .solTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -25px;
  }

  .solExpTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -30px;
  }

  .is-mobile .zci--calculator .zci__body {
    padding-right: 0px;
    margin-left: 0;
    padding-left: 1px;
  }
}

/* Small mobiles <= 310 */

@media only screen and (max-width: 19.375em) {

  .captureButton {
    position: absolute;
    left: 35%;
    bottom: 5%
  }

  .solcont {
    max-width: '100%';
    min-width: auto;
  }

  .solcontrow {
    width: 240px;
    margin: auto;
  }

  .solClip1 {
    margin-left: 10px;
  }

  .solClip2 {
    margin-left: 164px;
  }

  .SolutionStepbox {
    margin: 0;
    padding: 0px;
  }

  .backArrowBox {
    display: none;
  }

  .solBookmark {
    display: none;
  }

  .backArrowBoxMobile {
    display: flex;

  }

  .bookmarkMobile {
    display: flex;
  }

  .solTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -25px;
  }

  .solExpTitle {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -35px;
  }

  .zci--calculator .tile__calc .tile__history {
    position: relative;
    margin-top: 0;
  }
}

/*
 * DARK THEME
 */

.dark-bg .zci--calculator .tile__calc .tile__ctrl__btn {
  /* background: #2b2b2b;
  border: 1px solid #414141; */
  color: #fdfdfd;
}

.dark-bg .zci--calculator .tile__calc .tile__ctrl__btn:hover {
  background: rgba(88, 88, 88, 0.75);
}

.dark-bg .zci--calculator .tile__calc .tile__ctrl--ops,
.dark-bg .zci--calculator .tile__calc .tile__tab__sci .tile__ctrl__btn {
  background: #111;
  font-weight: lighter;
}

.dark-bg .zci--calculator .tile__calc .tile__ctrl--ops:hover,
.dark-bg .zci--calculator .tile__calc .tile__tab__sci .tile__ctrl__btn:hover {
  background: rgba(17, 17, 17, 0.7);
}

.dark-bg .zci--calculator .tile__calc .tile__ctrl__toggle {
  background: #111;
  border: 1px solid #414141;
  color: #fdfdfd;
}

.dark-bg .zci--calculator .tile__ctrl__toggle-slider {
  background: #2b2b2b;
}

.dark-bg .zci--calculator .tile__calc .tile__display__main {
  background: #2b2b2b;
}

.dark-bg .zci--calculator .tile__calc .tile__ctrl--important {
  background: rgb(220, 140, 0);
}

.dark-bg .zci--calculator .tile__calc .tile__ctrl--important:hover {
  background: rgba(220, 140, 0, 0.7);
}

.dark-bg .zci--calculator .tile__calc .tile__display {
  background: #2b2b2b;
  border: 1px solid #414141;
  box-shadow: none;
}

.dark-bg .zci--calculator .tile__calc .tile__display__main {
  color: #fdfdfd;
}

.dark-bg .zci--calculator .tile__calc .tile__display.selected {
  box-shadow: inset -1px -1px 0px #61a0c7, inset 1px 1px 0px #61a0c7;
}

.dark-bg .zci--calculator .tile__calc__col {
  border-bottom: none;
}

/* history */

.dark-bg .zci--calculator .tile__calc .tile__history {
  background-color: #111;
  border: 1px solid #414141;
}

.dark-bg .zci--calculator .tile__calc .tile__past-calc {
  background: #2b2b2b;
}

.dark-bg .zci--calculator .tile__calc .tile__past-result {
  color: #fdfdfd;
}

.dark-bg .zci--calculator .tile__calc .tile__past-calc {
  border-bottom: 1px solid #414141;
}

.dark-bg .zci--calculator .tile__calc .tile__past-calc:hover {
  background: #111;
}

/* .MathJax mtext {
  margin-right: 5px;
}

#react-mathjax-preview .MathJax {
  border: 1px solid #e8e8e8 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  padding-left: 15px;
  position: relative;
  max-width: 600px;
  display: block;
  border-left: 3px solid #01497c !important;
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000 !important;
  overflow: auto;
}

#react-mathjax-preview .MathJax:last-of-type {
  background: #01497c !important;
  color: white !important;
  
} */

.goto-solution-steps {
  font-size: 10pt;
  color: #01497c;
  cursor: pointer;
}

.eq-left-items {
  border-left: 3px solid red;
  margin-right: 10px;
  height: 30px;
}

.eq-left-text {
  font-size: 9pt;
  color: red;
  margin-left: -3px;
  font-weight: bold;
}

.eq-right-items {
  border-left: 3px solid green;
  margin-right: 10px;
  height: 30px;
}

.eq-right-text {
  font-size: 9pt;
  color: green;
  margin-left: -3px;
  font-weight: bold;
}

.sol-right-items {
  border-left: 3px solid blue;
  margin-right: 10px;
  height: 30px;
}

.sol-right-text {
  font-size: 9pt;
  color: blue;
  margin-left: -3px;
  font-weight: bold;
}

.word-break-all {
  word-break: break-all;
}

/* History */
.history-container {
  /* max-width: 628px; */
  margin: auto;
}



history .history-segment-container {
  margin: auto;
  width: 750px;
}

.histcont {
  /* width: 420px; */
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center;
  font-family: "RobotoRegular";
}

.history-horizantal-flex {
  /* flex-direction: column; */
  /* flex: 1; */
  /* align-items: center; */
  /* align-content: center; */
  margin-left: 350px;
  /* margin: auto; */
  height: 70px;
}

.history-card-container {
  border-style: solid;
  border-width: 1px;
  border-color: #e8e8e8;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  /* margin-bottom: 20px; */
  background: white;
  border-radius: 10px;
  /* margin-left: 300px; */
  margin: auto;
  margin-bottom: 20px;
  max-width: 400px;
  height: fit-content;
  min-height: 180px;
  padding: 10px;
  box-shadow: 1px 1px 1px 1px #888888a6;
}

.history-equation-card {
  margin: 10px;
}

.history-equation-options {
  /* width: 100px; */
  /* height: 20px; */
  float: right;
  fill: #01497c;
  /* margin-right: 10px; */
}

.history-equation-option-icon {
  margin-right: 15px;
}

.history-equation-option-right {
  float: right;
}

.history-equation-option-dropdown {
  border: 1px solid #000000;
  background-color: #00000000;
  top: 20px;
  right: 10px;
  position: absolute;
}

.history-drop-down-item {
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
}

.history-solution-card {
  margin: 10px;
}

.history-equation {
  color: #01497c;
  font-size: 24px;
  font-weight: 500;
}

.history-date {
  color: #a4a4a4;
  font-size: 10px;
  margin-top: 16px;
}

.segmented-control>label {
  line-height: 2em;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
}

/* expandable view */
.expandable-listview_outerDiv {
  height: 400px;
  overflow-y: auto;
  outline: 1px dashed blue;
  width: 400px;
}

.expandable-listview_ul {
  margin: 0px;
  list-style-type: none;
  padding: 0;
}

.expandable-listview_fixedPosition {
  position: fixed;
  width: 383px;
  top: 0px;
}

.expandable-listview_listHeader {
  height: 50px;
  background: orange;
  color: white;
}

.expandable-listview_listItems {
  color: blue;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  left: -140px;
  top: 16px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #e8e8e8;
  z-index: 1;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-content-item {
  border: 1px solid #e8e8e8;
  color: #333333;
  padding: 5px 0px 5px 10px;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.sidebar-nav {
  color: #01497c;
  font-size: 16px;
  font-weight: 500;
  max-width: 600px;
}

.sidebar-nav-menu-item-head-arrow {
  float: right;
  margin-top: 5px;
}

.sidebar-nav-menu-item {
  display: block;
}

.sidebar-nav-menu-item-head {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sidebar-nav-menu-item-body {
  display: none;
  font-weight: 400;
  color: #000000;
}

.sidebar-nav-menu-item.item-active .sidebar-nav-menu-item-body {
  display: block;
}

.container h2 {
  font-weight: 500;
}

.box1 {
  border: solid;
  border-width: thin;
  border-radius: 10px;
  font-size: 20px;
  width: max-content;
  padding: 7px;
}

.problembox {
  border-width: 2px;
  height: max-content;
  margin-bottom: 20px;
  background: white;
  min-width: 100px;
  border-color: #01497c;
  color: #01497c;
  text-align: center;
}

.resultbox {
  height: 45px;
  background-color: #01497c;
  color: white;
  min-width: 100px;
  border-color: #01497c;
  text-align: center;
}




.clipline {
  border: 1px solid rgba(0, 0, 0, 0.514);
  position: fixed;
  height: 48px;
  margin-top: -15px;
}

.line1 {
  margin-left: 29px;
}

.line2 {
  margin-left: 34px;
}

.line3 {
  margin-left: 309px;
}

.line4 {
  margin-left: 314px;
}

.clipdot {
  background-color: rgba(0, 0, 0, 0.514);
  height: 6px;
  width: 15px;
}

.dot1 {
  margin-left: 20px;
  margin-top: 5px;
}

.dot2 {
  margin-left: 265px;
  margin-top: 5px;
}

.dot3 {
  margin-left: 20px;
  margin-top: -5px;
}

.dot4 {
  margin-left: 265px;
  margin-top: -5px;
}

.imagebox {
  border-width: thin;
  border-radius: 20px;
  padding: 10px;
  color: black;
  border-color: white;
  width: 300px;
  height: 450px;
  text-align: center;
  background: white;
  margin-right: 30px;
  margin-bottom: 30px;
  font-family: "RobotoRegular";
}

.imginsidebox {
  border-width: thin;
  border-radius: 20px;
  margin: 20px;
  margin-left: 40px;
  margin-right: 40px;
  height: 200px;
  width: 200px;
  background: #e2ecff;
  border-color: white;
  padding: 5px;
  position: relative;
}

.box2 {
  border-width: thin;
  border-radius: 10px;
  padding: 10px;
  padding-left: 20px;
  /* width: 350px; */
  margin: auto;
  font-weight: 600;
  box-shadow: 1px 1px 1px 1px #888888a6;
  font-size: 18px;
}

.equationbox {
  color: black;
  border-color: white;
  min-height: 100px;
  background: white;
}

.solPageUI {
  height: fit-content;
  width: fit-content;
  margin: auto;
}

.solutionbox {
  color: white;
  border-color: white;
  height: 150px;
  background: #01497C;
}

.solbtn {
  color: #01497c !important;
  background-color: white !important;
  border-color: #01497c !important;
  padding: 5px 15px;
  margin-top: 15px;
  font-weight: 500 !important;
  font-size: 16px !important;
  border-radius: 10px;
}



.divider {
  background-color: #c2c2c25b;
  height: 1px;
}

.calc-divider-line {
  margin-left: 150px;
  margin-right: 150px;
  height: 5px;
}

.history-divider-line {
  margin-left: -10px;
  margin-right: -10px;
}

.sol-divider-line {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -20px;
  margin-right: -10px;
}

.step-divider-line {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -10px;
  margin-right: -10px;
}

.tittle {
  text-align: center;
  font-family: "RobotoRegular";
  font-size: 35px;
}

.bkm {
  width: 15px;
  height: 20px;
  float: right;
  margin-top: 20px;
}

.imgtittle {
  font-weight: "500";
  font-size: "32px";
  margin-bottom: "revert";
}

.imgcont {
  max-width: 650px;
  margin: auto;
}

.imgsubtittle {
  margin: "20px";
  font-size: "14px";
  font-weight: "400";
}

.imgbtn {
  background-color: transparent;
  border: transparent;
  float: right;
}

.imgbtn1 {
  background-color: gray;
  opacity: 0.6;
  border: 1px solid lightgray;
  float: right;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 20px;
  font-size: 14px;
  font-family: "RobotoRegular";
}

.txtbtn {
  background-color: transparent;
  border: transparent;
  font-size: 16px;
}

.flip-card {
  background-color: transparent;
  width: 350px;
  height: 260px;
  perspective: 1000px;

}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;

}

.flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: max-content;
  height: max-content;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}


.flip-card-front {
  background-color: #fff;
  color: black;
}

.flip-card-back {
  background-color: #fff;
  color: white;
  transform: rotateY(180deg);
}

.cardHide {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.cardShow {
  opacity: 1;
  height: auto;
}

/* calculator */
.calc_cursor {
  background-color: 'red';
  width: 3;
  height: 24;
}

.calc_symbol {
  color: 'grey';
  font-size: 20;
  padding-left: 2;
  padding-right: 2;
  height: 25;
}

.calc_equation {
  color: 'black';
  font-size: 20;
  height: 25;
  text-align: 'center';
  /* textAlignVertical: 'center'; */
  align-items: 'center';
  min-width: 5;
}

.calc_emptyEquationBox {
  width: 15;
  border-width: 10;
  border-color: 'red';
}

.calc_emptyEquationInvisible {
  width: 10;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.view {
  background-color: white;
  position: relative;
  top: 1000px;
  transition: top 1.5s ease-in;
}

.calbtnMore {
  background-color: '#f0f0f0';
  transition: background-color .5s;}

.calbtnMore:hover{
  background-color: white;
}