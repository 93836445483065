@font-face {
  font-family: "SF UI Display Regular";
  src: local("SF UI Display Regular"),
   url("./fonts/SF-UI-Display-Regular.ttf") format("truetype");
  font-weight: bold;
  }
  
  @font-face {
    font-family: "RobotoRegular";
    src: local("RobotoRegular"),
     url("./fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: bold;
    }
  

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.each-step,
.final-solution {
  position: relative;
  background: #01497C;
  color: white;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 20px;
}
.final-solution {
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
}
.step-nums {
  position: absolute;
  top: 30px;
  left: 8px;
  font-size: 10pt;
  font-weight: bold;
}
.final-soln-txt {
  text-align: left;
  font-weight: normal;
  margin-right: 10px;
  margin-left: 10px;
}
.formattedStep {
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 12px;
  position: relative;
  top: 11px;
  left: -2px;
  padding: 10px;
}
.dzu-dropzone {
  border: 1px solid #01497C !important;
  overflow: hidden !important;
  max-width: 500px;
  border-radius: 0px !important;
  background: rgba(54, 121, 251, 0.1);
}
.dzu-inputLabel {
  color: #01497C !important;
  font-size: 17px !important;
}

.dzu-cta {
  text-align: center;
  font-weight: normal;
  font-size: 15px;
  width: 260px;
  padding: 10px;
}

.btn-dzu-cta {
  background: #01497C !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 500;
}

.btn-dzu-cta:hover {
  background: #2a60ca;
}

.mjx-chtml.MJXc-display {
  font-family: Inter;
  font-style: normal;
  text-align: left !important;
  font-size: 18px;
  font-weight: 500;
}

.equation-box .mjx-chtml.MJXc-display {
  color: #01497C;
}

.final-solution .mjx-chtml.MJXc-display {
  color: white !important;
}

.sol-card-box {
  border: 1px solid #e8e8e8 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  padding-left: 15px;
  position: relative;
  max-width: 600px;
}

.sol-card-box.sol-step {
  border-left: 3px solid #01497C !important;
}

.sol-card-box.bl-theme {
  color: #ffffff !important;
}

.sol-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000 !important;
}

.sol-card-title.bl-theme {
  color: #ffffff !important;
}

.sol-card-content {
  /* color: #01497C; */
  color: #000000 !important;
  font-size: 18px;
  font-weight: 500;
}

.sol-card-content.bl-theme {
  color: #ffffff !important;
}

.multi-steps-arrow {
  position: absolute;
  top: 0px;
  right: 10px;
  color: #01497C;
  cursor: pointer;
}
